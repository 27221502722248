<template>
  <div>
    <v-dialog v-model="showDialog" width="1200px" class="pa-0 ma-0">
      <v-card style="min-height: 60vh">
        <v-toolbar class="primary" dark
          >Observações
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="closeModal"> x </v-btn></v-toolbar
        >

        <v-card-text>
          <v-progress-circular
            v-show="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-timeline>
            <v-timeline-item v-for="(comment, n) in comments" :key="n" large>
              <template v-slot:icon>
                <v-avatar>
                  <!-- <img src="https://i.pravatar.cc/64" /> -->
                  <h3 style="color: white">
                    {{ comment?.user?.username?.[0] }}
                  </h3>
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span>{{ comment?.user?.username }}</span>
                <p v-datetime-format="comment.createdAt"></p>
              </template>
              <v-card class="elevation-2">
                <v-card-title class="text-h5">{{
                  comment?.title
                }}</v-card-title>
                <v-card-text>{{ comment.value }}</v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-text style="">
          <!-- <b class="my-2" style="">Novo comentário</b> -->

          <v-card class="pa-2 my-2">
            <div>
              <v-textarea
                label="Nova observação"
                v-model="newCommentValue"
              ></v-textarea>
            </div>
            <div style="display: flex; justify-content: space-between">
              <span></span>
              <v-btn class="primary" @click="postComment()">Salvar</v-btn>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { multiply } from "lodash";
import { mapState } from "vuex";

export default {
  name: "dialog-commments",
  props: ["value", "process"],
  components: {},

  data() {
    return {
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 50,
      serverItemsLength: 15,
      nfses: [],
      comments: [],
      newCommentValue: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  async mounted() {
    //  await this.getData();
  },

  watch: {
    showDialog(val, oldVal) {
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    closeModal() {
      this.showDialog = false;
    },
    async postComment() {
      let newComment = {
        value: this.newCommentValue,
        process: this.process?.id,
        user: this.user.id,
      };

      //console.log(JSON.stringify({ newComment }));

      let ans = await strapiApi.processComments.post(newComment);
      // console.log(JSON.stringify({ ans }));

      await this.getData();
      this.newCommentValue = "";
      console.log("finish");
    },
    async getData() {
      console.log("get data");
      this.loading = true;
      let { values, pagination } =
        await strapiApi.processComments.getWithPagination(
          1,
          25,
          "createdAt",
          false,
          this.process.id
        );

      this.comments = values;

      this.loading = false;
      console.log(JSON.stringify({ values, pagination }));
    },
  },
};
</script>
