import functions from "./functions";
var processComments = {
  // async getWithPagination(
  //   page,
  //   pageSize,
  //   sort,
  //   sortDesc,
  //   enterpriseId,
  //   distribution
  // ) {
  //   const start = Date.now();

  //   var filters = {
  //     // VLR_ABERTO: {
  //     //   gt: 0,
  //     // },
  //   };

  //   if (distribution) {
  //     if (enterpriseId) {
  //       filters.pedido = {
  //         enterprise: {
  //           id: {
  //             eq: enterpriseId,
  //           },
  //         },
  //       };
  //     }
  //   } else {
  //     if (enterpriseId) {
  //       filters.process = {
  //         enterprise: {
  //           id: {
  //             eq: enterpriseId,
  //           },
  //         },
  //       };
  //     }
  //   }

  //   var query = `query processComments($filters:ReceivableFiltersInput!){
  //     processComments( sort:"${sort}${
  //     sortDesc ? ":desc" : ""
  //   }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
  //       meta{
  //         pagination{
  //           total
  //           pageSize
  //           page
  //         }
  //       }
  //       data{
  //         id
  //         attributes{
  //           DOC_COD
  //           TIT_DTA_VENCIMENTO
  //           VLR_TOT
  //           VLR_PAGO
  //           VLR_ABERTO
  //           DOC_TIP
  //           viewName
  //           raw
  //           process{
  //             data{
  //               id
  //               attributes{
  //                 PRI_ESP_REFERENCIA
  //                 PRI_ESP_REFCLIENTE
  //               }
  //             }
  //           }
  //           pedido{
  //             data{
  //               id
  //               attributes{
  //                 PED_DTA_EMISSAO
  //                 PED_COD
  //               }
  //             }
  //           }

  //         }
  //       }
  //     }
  //   }
  //   `;

  //   var graphql = JSON.stringify({
  //     query,
  //     variables: {
  //       filters,
  //     },
  //   });
  //   //if (window.debug)
  //   console.log(graphql);
  //   var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
  //   var res = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: graphql,
  //   })
  //     .then((res) => {
  //       if (window.debug) console.log(res);
  //       return res.json();
  //     })
  //     .then((json) => {
  //       if (window.debug) console.log(json);
  //       return json;
  //     });

  //   const end = Date.now();
  //   if (window.debug)
  //     console.log(
  //       `Execution getWithPagination processComments time: ${
  //         (end - start) / 1000
  //       } s`
  //     );

  //   const pagination = res?.data?.processComments?.meta?.pagination;
  //   const values = functions.sanitize(res.data.processComments.data);
  //   return { values, pagination };
  // },
  async getWithPagination(page, pageSize, sort, sortDesc, processId) {
    const start = Date.now();

    var filters = {};

    if (processId) {
      filters = {
        process: {
          id: { eq: processId },
        },
      };
    }

    var query = `query processComments($filters:ProcessCommentFiltersInput){
        processComments( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
            data{
              id
              attributes{
                createdAt
                value
                title
                user{
                  data{
                    id
                    attributes{
                      username
                    }
                  }
                }
              }
            }
        }
      }
    `;
    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination processComments time: ${
          (end - start) / 1000
        } s`
      );

    const pagination = res?.data?.processComments?.meta?.pagination;
    const values = functions.sanitize(res.data.processComments.data);
    return { values, pagination };
  },

  async post(comment) {
    const start = Date.now();

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/process-comments`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: comment }),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    return res;
  },
};

export default processComments;
